
    
<template>
    <div>
      <CModal title="Monitoring Jabatan Struktural - Uji Kompetensi" color="success" :show.sync="modalUjiKom" size="xl">
        <CRow>
            <CCol md="6">
                <CInput label="NIP - Nama" placeholder readonly v-model="nip_nama"></CInput>
            </CCol>
        </CRow>
        <CDataTable
          :items="computedItems"
          :fields="fields"
          hover
          small
          border
          :loading="loading"
          :pagination="true"
          :items-per-page="10"
        >
          <template #tanggal_tempat="{item}">
            <td class="py-2">
                {{ item.tgl }}/{{ item.tempat }}
            </td>
          </template>
          <template #action="{item}">
            <td class="py-2">
                <CButton
                    color="success"
                    square
                    size="sm"
                    class="ml-1"
                    variant="outline"
                    @click="downloadFile(item)"
                >Download File</CButton>
                <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    class="ml-1"
                    @click="showModalEditUjiKom(item)"
                >Edit</CButton>
                <CButton
                    size="sm"
                    color="danger"
                    variant="outline"
                    square
                    class="m-1"
                    @click="delete_item(item.id)"
                >Delete</CButton>
            </td>
          </template>
        </CDataTable>
      </CModal>

      <modal-edit-uji-kom ref="modalEditUjiKom" />
    </div>
  </template>
  <script>
  import modalEditUjiKom from "./modalEditUjiKom";
  import Swal from "sweetalert2";
  const fields = [
    { key: "number", label: "No" },
    { key: "jenis_jabatan", label: "Jenis Jabatan" },
    { key: "jabatan", label: "Jabatan" },
    { key: "kelas_jabatan", label: "Kelas Jabatan" },
    { key: "tanggal_tempat", label: "Tanggal/Tempat" },
    { key: "keterangan", label: "Keterangan" },
    { key: "action", label: "Action",sorter: false, filter: false, },
  ];
  export default {
    name: "ModalUjiKom",
    components: { modalEditUjiKom },
    data() {
      return {
        modalUjiKom: false,
        loading: false,
        fields: fields,
        items: [],
        nip: '',
        nama: '',
        nip_nama: '',
        jabatan: '',
      };
    },
    computed: {
      computedItems() {
        var number = 1;
        return this.items.map((item) => {
          return {
            ...item,
            number: number++,
            nip_nama: item.nip+' - '+item.nama_pegawai,
            jenis_jabatan: 'STRUKTURAL',
            jabatan: this.jabatan
          };
        });
      },
    },
    methods: {
      resetTable(item){
        this.loadData(item);
      },
      toggle(item) {
        this.items = [];
        this.modalUjiKom = !this.modalUjiKom;
        this.loadData(item);
        this.nip_nama = item.nip+' - '+item.nama_pegawai;
        this.nip = item.nip;
        this.nama = item.nama_pegawai;
        this.jabatan = item.nama_jabatan;
      },
      downloadFile(item) {
        window.open(item.upload_dokumen, "_blank");
      },
      async loadData(item) {
        try {
          this.loading = true;
          let data = await this.$store.dispatch("uji_kompetensi/by_pegawai", {
            nip: item.nip,
          });
          this.items = data;
        } catch (ex) {
          this.items = [];
        } finally {
          this.loading = false;
        }
      },
      delete_item(id) {
        Swal.fire({
          title: "Peringatan!",
          text: "Apa Anda yakin ingin menghapus data ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Hapus!",
        }).then(async (value) => {
          console.log(value);
          if (value.isConfirmed) {
            await this.$store.dispatch("uji_kompetensi/destroy", id);
            this.loadData();
            Swal.fire({
              title: "Ok!",
              text: "Data berhasil terhapus!",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok!",
            }).then(() => {
              this.modalUjiKom = false
            });
          }
        });
      },
      showModalEditUjiKom(item) {
        this.$refs.modalEditUjiKom.toggle(item);
      },
    },
  };
  </script>